import { gql } from "@apollo/client";

export const GET_NAVIGATE_LINK_TO_PAYMENT_PAGE = gql`
  query getNavigateLinkToPaymentPage($paymentID: Int!) {
    getPayment(paymentID: $paymentID) {
      id
      state
      isFirstPayment
      link
    }
  }
`;
