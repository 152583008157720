import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

import { PaymentProvider, PaymentState } from "@/autoGeneratedGlobalTypes";
import { isBrowser } from "@/utils/env";

import Loader from "../common/loader";

import { getNavigateLinkToPaymentPageVariables, getNavigateLinkToPaymentPage as getNavigateLinkType } from "./graphql/__generated__/getNavigateLinkToPaymentPage";
import { GET_NAVIGATE_LINK_TO_PAYMENT_PAGE } from "./graphql/GET_NAVIGATE_LINK_TO_PAYMENT_PAGE";

/**
 * Эта страница работает только для платежей через Robokassa. (новая карта)
 *
 * Что делает страница:
 * - Извлекает идентификатор платежа из URL, переданный от Robokassa через параметры InvId.
 * - Сохраняет этот идентификатор платежа в состоянии paymentIdFromRobokassa.
 * - Использует GraphQL-запрос GET_NAVIGATE_LINK_TO_PAYMENT_PAGE, чтобы получить ссылку для перенаправления
 *   на соответствующую страницу после успешного или неудачного платежа.
 * - В зависимости от состояния платежа (PaymentState.Failed или другое), происходит навигация на страницу результата
 *   с необходимыми параметрами платежа.
 */

const PaymentProcessing = () => {
  const [paymentIdFromRobokassa, setPaymentIdFromRobokassa] = useState<number | null>(null);
  const location = useLocation();

  const [getNavigateLinkToPaymentPage, { data, loading, error }] = useLazyQuery<
    getNavigateLinkType, getNavigateLinkToPaymentPageVariables
  >(GET_NAVIGATE_LINK_TO_PAYMENT_PAGE);

  useEffect(() => {
    if (!isBrowser()) return;

    const urlParams = new URLSearchParams(window.location.search);
    const invIdParam = Number(urlParams.get("InvId"));
    if (invIdParam) {
      setPaymentIdFromRobokassa(invIdParam);
    }
  }, []);

  useEffect(() => {
    if (paymentIdFromRobokassa) {
      getNavigateLinkToPaymentPage({ variables: { paymentID: paymentIdFromRobokassa } });
    }
  }, [paymentIdFromRobokassa, getNavigateLinkToPaymentPage]);

  useEffect(() => {
    if (loading || error || !data) return;

    const { getPayment } = data;
    const urlOrigin = location.origin;
    const navigateLink = getPayment.link?.replace(urlOrigin, "") || "";
    const requestType = getPayment.state === PaymentState.Failed ? "fail" : "success";

    navigate(`${navigateLink}?paymentId=${paymentIdFromRobokassa}&paymentProvider=${PaymentProvider.RobokassaPayments}&request=${requestType}`);
  }, [data, error, loading, location.origin, paymentIdFromRobokassa]);

  return loading ? <Loader /> : null;
};

export default PaymentProcessing;
